@tailwind base;

@layer base {
  :root {
    /*
    *
    * This all are the rgb values which are used inside the
    * tailwind config file and custom plugins css file
    *
    */

    /*
    * light color
    */
    --color-light: 255, 255, 255;

    /*
    * dark color
    */
    --color-dark: 0, 0, 0;

    /*
    * primary color
    */
    --color-primary: 44, 98, 57;

    /*
    * secondary color
    */
    --color-secondary: 238, 200, 140;

    /*
    * purple color
    */
    --color-purple: 110, 29, 191;

    /*
    *
    * TEXT colors
    *
    */

    /*
    * text color used for body/base text -> gray-500
    */
    --text-base: 107, 114, 128;

    /*
    * dark base text color  -> gray-600
    */
    --text-base-dark: 75, 85, 99;

    /*
    * muted text color -> gray-400
    */
    --text-muted: 156, 163, 175;

    /*
    * light muted text color - gray-300
    */
    --text-muted-light: 209, 213, 219;

    /*
    * text color used for heading text -> gray-700
    */
    --text-sub-heading: 55, 65, 81;

    /*
    * text color used for heading text -> gray-800
    */
    --text-heading: 31, 41, 55;

    /*
    * text color used for heading text -> gray-900
    */
    --text-bolder: 31, 41, 55;

  }

  html {
    -webkit-tap-highlight-color: transparent;

  }

  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

body {
  @apply font-body;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
}

a {
  @apply no-underline;
}
}

@tailwind components;
@tailwind utilities;